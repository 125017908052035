import 'htmx.org'

import { createApp } from 'petite-vue'

import Alpine from "alpinejs";
import Autosize from './autosize.js';

Alpine.plugin(Autosize);

window.Alpine = Alpine;

Alpine.start()

function DarkMode(props) {
  return {
    isDarkMode: props.isDarkMode,
    toggleDarkMode() {
      this.isDarkMode = !this.isDarkMode
      localStorage.setItem('isDarkMode', this.isDarkMode)
    },
    mounted() {
      // Clean up these console logs
      console.log(
        `Dark Mode System: `,
        window.matchMedia('(prefers-color-scheme: dark)').matches
      )
      console.log(`Dark Mode Local: `, localStorage.getItem('isDarkMode'))
      if (localStorage.getItem('isDarkMode') === 'false') {
        console.log('Setting darkmode FALSE from local')
        this.isDarkMode = false
      } else if (
        localStorage.getItem('isDarkMode') == 'true' ||
        window.matchMedia('(prefers-color-scheme: dark)').matches == true
      ) {
        console.log('Setting darkmode TRUE from local')
        this.isDarkMode = true
      } else {
        console.log('Setting darkmode FALSE')
        this.isDarkMode = false
      }
    },
  }
}


createApp({
  DarkMode,
  // DarkMode
}).mount()

